import React from 'react'
import Gpt from './Gpt';
export  const Ad1 = () => {
  Gpt({
    path:'/2163006/election_336x280_300x250',
    size:[300, 250],
    id:'div-gpt-ad-1599627962119-0',
  });
  return (
      <div id='div-gpt-ad-1599627962119-0' style={{ textAlign:'center', margin:'1em auto' }}/>
  )
}

export const Ad2 = () => {
  Gpt({
    path:'/2163006/election_336x280_300x250_2',
    size:[336, 280],
    id:'div-gpt-ad-1599823689403-0',
  });
  return (
      <div id='div-gpt-ad-1599823689403-0' style={{ textAlign:'center', margin:'1em auto' }}/>
  )
}

export const Ad3 = () => {
  Gpt({
    path:'/2163006/election_300x250_336x280_3',
    size:[300, 250],
    id:'div-gpt-ad-1599907131243-0',
  });
  return (
      <div id='div-gpt-ad-1599907131243-0' style={{ textAlign:'center', margin:'1em auto' }}/> 
  )
}

export const Ad4 = () => {
  Gpt({
    path:'/2163006/election_head',
    size:  [[300, 250], [336, 280], 'fluid', [1, 1]],
    id:'div-gpt-ad-1666944904129-0',
  });
  return (
    <div id='div-gpt-ad-1666944904129-0' style={{ textAlign:'center', margin:'0 auto' }}/>
  )
}
