import React,{useEffect,useState,Suspense} from 'react';
import { Collapse ,Button,BackTop} from 'antd';
import {ChartNP,Loader,Party} from '../index';
import { useParams ,Link} from 'react-router-dom';
import HTMLReactParser from 'html-react-parser';
import axios from 'axios';
import i18next from 'i18next';
import './SeatsDetail.css';
import {VerticalAlignTopOutlined } from '@ant-design/icons'
const {Panel}=Collapse;
const SeatsDetail = () => {
  
  const {parliment,state}=useParams();
  const [seatCode,setSeatCode]=useState(parliment);
  const [data,setData]=useState([]);
  const [expandAll,setExpandAll]=useState(false);
  const [done,setDone]=useState(undefined);
  const [seat,setSeat]=useState('');

  const fetchData = async(state,seatCode)=>{
    try{
      
      axios.get(`seatinfo.php?seat=${state}&code=${seatCode}`).then((response)=>{
        const datas=response.data
        setData(datas);
        setDone(true);
      })
    }catch(error){
      console.log(error)
    }
  }
  
  const activeIndex=()=>{
    const arr=[];
    for (let i=0 ;i<60;i++){
      arr[i]=i;
    }
    setSeat(arr);
  }
  useEffect(()=>{
    window.scrollTo(0, 0)
    fetchData(state,seatCode)
    setSeatCode(parliment)
    activeIndex()
    //loadComponent(state);
  },[state,seatCode,parliment])
  
  const expandFunctions=()=>{
    setExpandAll(!expandAll);
    if(expandAll===true){
      const arr=[];
      for (let i=0 ;i<data.length;i++){
        arr[i]=i;
      }
      setSeat(arr);
      
    }else{
      setSeat([]);
    }
  }

  
  const convertName = (state) => {
    switch (state) {
      case "johor":
        state = "柔佛";
        return state;
        
      case "kedah":
        state = "吉打";
        return state;
        
      case "penang":
        state = i18next.t("槟城");
        return state;
        
      case "kelantan":
        state = i18next.t("吉兰丹");
        return state;

      case "melaka":
        state = i18next.t("马六甲");
        return state;
        
      case "negerisembilan":
        state = i18next.t("森美兰");
        return state;
        
      case "selangor":
        state = i18next.t("雪兰莪")
        return state;
        
      case "sabah":
        state = "沙巴";
        return state;
        
      case "terengganu":
        state = i18next.t("登嘉楼");
        return state;
        
      case "sarawak":
        state = i18next.t("砂拉越");
        return state;
        
      case "perlis":
        state = i18next.t("玻璃市");
        return state;
        
      case "perak":
        state =i18next.t("霹雳");
        return state;
        
      case "pahang":
        state = "彭亨";
        return state;
        
      case "kl":
        state =i18next.t("联邦直辖区");
        return state;
        
      default:
        state = "error";
        return state;
    }
  };
  const parlimentStateFunction=(e)=>{
    setExpandAll(false);
  }
  
  function loadComponent(name){
    const Component=React.lazy(()=>
       import (`../../map/${name}`)
    );
    return Component
  }
  const topStyle={
    height: 40,
    width: 40,
    lineHeight: '40px',
    borderRadius: 20,
    backgroundColor: 'red',
    color: '#fff',
    textAlign: 'center',
    fontSize: 20,
  }
  const Component=loadComponent(state)
  if (!done) return <Loader/>;
  
  return (
    <> 
      <div className='title_seat'>
         <h1 className='title'>{convertName(state)}</h1>
      </div>
      <div className='button_expand'>
        <Button type="primary" value="p" danger size='large' onClick={parlimentStateFunction} style={{marginRight:'10px',zIndex:'10'}}><Link to={`../seats/${state}/p`}>国会议席</Link></Button>
        {(state==='pahang' || state==='perlis' || state==='perak') &&<Button type="primary" value="n" style={{zIndex:'10'}}danger size='large' onClick={parlimentStateFunction}><Link to={`../seats/${state}/n`}>州议席</Link></Button>}
      </div>
      <div className='chartNP'> 
        {/* <ChartNP state={state} code={seatCode}/> */}
      </div>
      <div className='table_party'>
        <Party/>
      </div>
      <div className='seat_map'>
        <h1 >{convertName(state)+"-地图"}</h1>
        <div className='map'>
          <Suspense fallback={<Loader/>}>
           <Component code={seatCode} state={state} />
          </Suspense>
        </div>
      </div>
      <div className='button_expand'>
        <Button type="primary"  style={{backgroundColor:'grey',borderRadius:'10px',borderColor:'white'}}  onClick={expandFunctions}>{expandAll ? '显示全部 ' : '隐藏全部'} </Button>
      </div>
      <div className='seat_info'>
          {data.map((cand,index)=>(
            <section id={cand.seatname} key={index}>
             <Collapse activeKey={seat} onChange={setSeat} key={index} className='collapse_seat' >
               <Panel className='panel_seat' header={cand.seatname +"  "+ cand.ednamec +" "+ cand.edname} key={index} >
               {HTMLReactParser(cand.candidate+cand.majority+cand.total_votes+cand.electorate+cand.reg_percent+cand.last_result)}
               </Panel>
             </Collapse>
             </section>
           ))}
      </div>
      <BackTop>
         <div style={topStyle}><VerticalAlignTopOutlined /></div>
      </BackTop>
    </>
  )
}
export default SeatsDetail