import ReactPlayer from "react-player";
import {useState,useEffect} from 'react';
import './Video.css';

export const Video1=()=>{

  const [video,setVideo]=useState('');
  const fetchVideo=async()=>{
    const response=await fetch ('https://testelec2022.orientaldaily.com.my/admin/cache/pinvid.txt');
    const data=await response.text();
    setVideo(data);
    
  }
  useEffect(()=>{
    fetchVideo();
  },[video])

    return(
      <>
      {video.length>30?
      <div className='player-wrapper'>
           <ReactPlayer
                   className='react-player'
                   url={video}
                   width='100%'
                   height='100%'
                 />
         </div>
      :null}
      </>
    )
}
export const Video2=()=>{
  const [video,setVideo]=useState('');
  const fetchVideo=async()=>{
    const response=await fetch ('https://testelec2022.orientaldaily.com.my/admin/cache/pinvid2.txt');
    const data=await response.text();
    setVideo(data);
    
  }
  useEffect(()=>{
    fetchVideo();
  },[video])
    return(
      <>
      {video.length>30?
      <div className='player-wrapper'>
           <ReactPlayer
                   className='react-player'
                   url={video}
                   width='100%'
                   height='100%'
                 />
         </div>
      :null}
      </>
        
    )
}