import {Chart,AllParty,StateParty} from '../';
import './Seats.css';
const Seats = () => {
  
  // if (!done) return <Loader/>;
  return (
    <> 
      <div className='chart'>
        {/* <Chart /> */}
      </div>
      
      <div className='table_party'>
        <AllParty/>
      </div>

      <div className='state_main' style={{textAlign:'center',marginTop:'3em'}}>
        <StateParty/>
      </div>  
    </>
  )
}
export default Seats