import {useEffect,useState} from 'react'
import { Table } from 'antd';
import { Link } from 'react-router-dom';
import axios from 'axios';

import './StateParty.css';
import i18next from 'i18next';
const StateParty = () => {
  const [dataP,setPData]=useState();
  const [dataN,setNData]=useState();

  const props={
      bordered:true,
      pagination:false,
      size:'small'
  }
  
  const fetchParliment=async()=>{
    axios.get('parliment.php').then((response)=>{
      const dataParliment=response.data;
      setPData(dataParliment);
    })
  }
  const fetchNegeri=async()=>{
    axios.get('negeri.php').then((response)=>{
      const dataNegeri=response.data;
      setNData(dataNegeri);
    })
  }

  useEffect(()=>{
    fetchParliment();
    fetchNegeri();
  },[])

  const parlimentCol=[
    {
      title:<p style={{textAlign:'center',fontWeight:'600',margin:'auto'}}>{i18next.t("国会议席")}</p>,
      dataIndex:"country",
      key:"country",
      align:'center',
      width:'20%',
      render:(text,record)=>
      <Link to={text.toLowerCase()+"/p"}>
        <img src={record.icon} alt="icon" style={{width:'25px',height:'25px'}} /><br/>{convertName(text.toLowerCase())}
      </Link>
    },
    {
      title:<p style={{fontWeight:'600',margin:'auto'}}>{i18next.t("国阵")}</p>,
      dataIndex:"bn",
      key:"bn",
      align:'center',
  

    },
    {
      title:<p style={{fontWeight:'600',margin:'auto'}}>{i18next.t("希盟")}</p>,
      dataIndex:"ph",
      key:"ph",
      align:'center',
      
    },
    {
      title:<p style={{fontWeight:'600',margin:'auto'}}>{i18next.t("国盟")}</p>,
      dataIndex:"pn",
      key:"pn",
      align:'center',
      
    },
    {
      title:<p style={{fontWeight:'600',margin:'auto'}}>砂盟</p>,
      dataIndex:"gps",
      key:"gps",
      align:'center',
      
    },
    {
      title:<p style={{fontWeight:'600',margin:'auto'}}>{i18next.t("沙盟")}</p>,
      dataIndex:"grs",
      key:"grs",
      align:'center',
      
    },
    {
      title:<p style={{fontWeight:'600',margin:'auto'}}>{i18next.t("民興黨")}</p>,
      dataIndex:"warisan",
      key:"warisan",
      align:'center',
      
    },
    
    {
      title:<p style={{fontWeight:'600',margin:'auto'}}>其他</p>,
      dataIndex:"other",
      key:"other",
      align:'center',
    }
  ]

  const negeriCol=[
    {
      title:<p style={{textAlign:'center',fontWeight:'600',margin:'auto'}}>{i18next.t("州议席")}</p>,
      dataIndex:"country",
      key:"country",
      align:'center',
      width:'20%',
      render:(text,record)=><Link to={text.toLowerCase()+"/n"}><img src={record.icon} alt="icon" style={{width:'25px',height:'25px'}} /><br/>{convertName(text.toLowerCase())}</Link>
    },
    {
      title:<p style={{fontWeight:'600',margin:'auto'}}>{i18next.t("国阵")}</p>,
      dataIndex:"bn",
      key:"bn",
      align:'center',
      
    },
    {
      title:<p style={{fontWeight:'600',margin:'auto'}}>{i18next.t("希盟")}</p>,
      dataIndex:"ph",
      key:"ph",
      align:'center',
      
    },
    {
      title:<p style={{fontWeight:'600',margin:'auto'}}>{i18next.t("国盟")}</p>,
      dataIndex:"pn",
      key:"pn",
      align:'center',
      
    },
    {
      title:<p style={{fontWeight:'600',margin:'auto'}}>砂盟</p>,
      dataIndex:"gps",
      key:"gps",
      align:'center',
      
    },
    {
      title:<p style={{fontWeight:'600',margin:'auto'}}>{i18next.t("沙盟")}</p>,
      dataIndex:"grs",
      key:"grs",
      align:'center',
      
    },
    {
      title:<p style={{fontWeight:'600',margin:'auto'}}>{i18next.t("民興黨")}</p>,
      dataIndex:"warisan",
      key:"warisan",
      align:'center',
      
    },
    
    {
      title:<p style={{fontWeight:'600',margin:'auto'}}>其他</p>,
      dataIndex:"other",
      key:"other",
      align:'center',
      
    }
]

  const convertName = (state) => {
    switch (state) {
      case "johor":
        state = "柔佛";
        return state;
        
      case "kedah":
        state = "吉打";
        return state;
        
      case "penang":
        state = i18next.t("槟城");
        return state;
        
      case "kelantan":
        state = i18next.t("吉兰丹");
        return state;

      case "melaka":
        state = i18next.t("马六甲");
        return state;
        
      case "negerisembilan":
        state = i18next.t("森美兰");
        return state;
        
      case "selangor":
        state = i18next.t("雪兰莪")
        return state;
        
      case "sabah":
        state = "沙巴";
        return state;
        
      case "terengganu":
        state = i18next.t("登嘉楼");
        return state;
        
      case "sarawak":
        state = i18next.t("砂拉越");
        return state;
        
      case "perlis":
        state = i18next.t("玻璃市");
        return state;
        
      case "perak":
        state =i18next.t("霹雳");
        return state;
        
      case "pahang":
        state = "彭亨";
        return state;
        
      case "kl":
        state =i18next.t("联邦直辖区");
        return state;
        
      default:
        state = "error";
        return state;
    }
  };
  return (
      <>
        <Table {...props} className="state_party1" dataSource={dataP} columns={parlimentCol} 
        summary={(pageData)=>{
          let totalBN=0
          let totalPH=0
          let totalPN=0
          let totalGPS=0
          let totalGRS=0
          let totalWarisan=0
          let totalOther=0
          
          pageData.forEach(
            ({bn,ph,pn,gps,grs,warisan,other})=>{
              totalBN+=bn
              totalPH+=ph
              totalPN+=pn
              totalGPS+=gps
              totalGRS+=grs
              totalWarisan+=warisan
              totalOther+=other
            }
          )
          return(
            <>
            <Table.Summary.Row style={{textAlign:'center',fontWeight:'bold'}}>
              <Table.Summary.Cell index={0}>{i18next.t("总计")}</Table.Summary.Cell>
              <Table.Summary.Cell index={1}>{totalBN}</Table.Summary.Cell>
              <Table.Summary.Cell index={2}>{totalPH}</Table.Summary.Cell>
              <Table.Summary.Cell index={3}>{totalPN}</Table.Summary.Cell>
              <Table.Summary.Cell index={4}>{totalGPS}</Table.Summary.Cell>
              <Table.Summary.Cell index={5}>{totalGRS}</Table.Summary.Cell>
              <Table.Summary.Cell index={6}>{totalWarisan}</Table.Summary.Cell>
              <Table.Summary.Cell index={7}>{totalOther}</Table.Summary.Cell>
            </Table.Summary.Row>
            </>
          )
        }}
        />
        <Table {...props} className="state_party1" dataSource={dataN} columns={negeriCol} 
        summary={(pageData)=>{
          let totalBN=0
          let totalPH=0
          let totalPN=0
          let totalGPS=0
          let totalGRS=0
          let totalWarisan=0
          let totalOther=0
          
          pageData.forEach(
            ({bn,ph,pn,gps,grs,warisan,other})=>{
              totalBN+=bn
              totalPH+=ph
              totalPN+=pn
              totalGPS+=gps
              totalGRS+=grs
              totalWarisan+=warisan
              totalOther+=other
            }
          )
          return(
            <>
            <Table.Summary.Row style={{textAlign:'center',fontWeight:'bold'}}>
              <Table.Summary.Cell index={0}>{i18next.t("总计")}</Table.Summary.Cell>
              <Table.Summary.Cell index={1}>{totalBN}</Table.Summary.Cell>
              <Table.Summary.Cell index={2}>{totalPH}</Table.Summary.Cell>
              <Table.Summary.Cell index={3}>{totalPN}</Table.Summary.Cell>
              <Table.Summary.Cell index={4}>{totalGPS}</Table.Summary.Cell>
              <Table.Summary.Cell index={5}>{totalGRS}</Table.Summary.Cell>
              <Table.Summary.Cell index={6}>{totalWarisan}</Table.Summary.Cell>
              <Table.Summary.Cell index={7}>{totalOther}</Table.Summary.Cell>
            </Table.Summary.Row>
            </>
          )
        }}
        />
      </>
  )
}

export default StateParty